<template>
  <div class="ticket">
    <!-- 第一部分 -->
    <OrderHead
      :hotelOrderInfo="hotelOrderInfo"
      :roomticket="roomticket"
      :hotelticket="hotelticket"
    />
    <!-- <div class="gap"></div> -->
    <!-- 第二部分 -->
    <!-- 房间 -->
    <van-panel
      title="入住信息"
      class="checkIn auto-cell-reverse"
      :status="'每间可住：'+roomticket.capacity+'人'"
    >
      <van-cell
        title="房间数量"
        is-link
        arrow-direction="down"
        class="auto"
        @click.self.stop="$refs.dropdown.toggle()"
      >
        <template>
          <span class="red null-peo" v-if="!(0 in newPersonList)">无合规人员</span>
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="checkRoomNumber"
              :options="roomNumber"
              ref="dropdown"
              @change="roomNumChange"
            />
          </van-dropdown-menu>
        </template>
      </van-cell>
      <el-select
        v-for="index in checkRoomNumber"
        :key="index"
        v-model="roomPeo[index]"
        multiple
        placeholder="请选择入住人员"
        class="select"
        :multiple-limit="roomticket.capacity"
        popper-class="peo-pop"
        @change="change($event,index)"
        @remove-tag="change($event,0)"
      >
        <template slot="prefix">
          <span class="select-title van-cell">房间 {{index}}</span>
        </template>
        <el-option
          :disabled="!(!item.hasOwnProperty('roomNo') || item.roomNo==0 || item.roomNo==index)"
          v-for="item in peoList"
          :key="item.idCard"
          :label="item.username"
          :value="item.idCard"
        ></el-option>
      </el-select>
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="arrivetime"
        input-align="right"
        label="预计到店"
        placeholder="点击选择时间"
        right-icon="clock-o"
        @click="showPickerTime = true"
      />
    </van-panel>

    <van-popup v-model="showPickerTime" position="bottom">
      <van-picker
        show-toolbar
        title="预计到店时间"
        :columns="columns"
        @confirm="onConfirmTime"
        @cancel="showPickerTime=false"
      />
    </van-popup>

    <!-- 二点五部分 -->
    <div class="gap"></div>
    <van-cell title="供应商" :value="roomticket.name">
      <template #default>
        <Logo :corp="hotelticket.corp" />
      </template>
    </van-cell>
    <van-cell title="房间类型" :value="roomticket.name" />
    <van-cell title="到店日期" :value="hotelOrderInfo.checkInDate|comverTime('YYYY-MM-DD')" />
    <van-cell title="离店日期" :value="hotelOrderInfo.departDate|comverTime('YYYY-MM-DD')" />
    <van-cell title="支付方式" :value="roomticket.ratePlans[0].paymentType" />

    <!-- 第三部分 -->
    <div class="gap"></div>
    <BodyList />
    <!-- 第四部分 -->
    <div class="bottom-gap"></div>
    <van-submit-bar
      :price="totalPrice*100"
      :disabled="!(0 in newPersonList)"
      button-text="提交订单"
      @submit="onSubmit"
    >
      <template #default>
        <van-button plain hairline type="info" size="mini" @click="detailShow=true">价格明细</van-button>
      </template>
    </van-submit-bar>

    <!-- 价格明细弹出层 -->
    <van-dialog v-model="detailShow" title="价格明细" close-on-click-overlay>
      <van-cell-group>
        <van-cell title="入住日期" value="当日价格" />
        <div style="max-height:40vh;overflow:auto">
          <van-cell
            v-for="(data,i) in roomticket.ratePlans[0].nightlyRates"
            :key="i"
            :title="data.date"
            :value="`￥${data.price} x ${checkRoomNumber}间`"
          />
        </div>
      </van-cell-group>
    </van-dialog>

    <!-- 遮罩层 -->
    <van-overlay :show="wrapper">
      <div class="wrapper" @click.stop>
        <LoadingAnimate :text="'占座中'" />
      </div>
    </van-overlay>

    <!-- 超标填表 -->
    <van-dialog v-model="compliant" title="超标说明" show-cancel-button :beforeClose="subimtCompliance">
      <van-form ref="editCompliant" class="explanCont">
        <van-field
          readonly
          v-model="explanation"
          label="超标缘由"
          placeholder="请输入超标缘由"
          @click="showPicker1 = true"
        />
        <van-field
          :rules="[{ required: true, message: '请填写超标缘由' }]"
          v-model="explanation1"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          maxlength="100"
          placeholder="请输入备注"
          show-word-limit
        />
      </van-form>
      <div style="padding:0 1rem;font-size:12px;color:#777;display:flex">
        <div style="width:70px">
          <small>超标人员：</small>
        </div>
        <div style="padding-bottom:1rem">
          <small
            v-for="(item,i) in explanationText"
            :key="i"
          >{{item.username}}（最高{{item.hotelStand}}元）</small>
        </div>
      </div>
    </van-dialog>

    <van-popup v-model="showPicker1" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns1"
        @cancel="showPicker1 = false"
        @confirm="onConfirm1"
      />
    </van-popup>

    <LoadingCircle :show="wrapper1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingAnimate from "@/components/LoadingAnimate";
import LoadingCircle from "@/components/LoadingCircle";
import { createHotelOrder } from "@/api/hotel";
import BodyList from "@/components/InfoList";
import OrderHead from "./components/OrderHead";
import { TimePickList } from "@/common/config";
import { Recommend } from "@/services/hotel";
import { REASONS } from "@/common/config";
import Logo from "@/components/Logo";

export default {
  components: { LoadingAnimate, Logo, BodyList, OrderHead, LoadingCircle },
  computed: {
    ...mapGetters([
      "roomticket",
      "hotelticket",
      "personList",
      "travelInfo",
      "hotelOrderInfo",
    ]),
    // 总票价
    totalPrice: function () {
      let price = 0; // 每日价格求和
      this.roomticket.ratePlans[0].nightlyRates.forEach((e) => {
        price += parseFloat(e.price);
      });
      return price * this.checkRoomNumber; // 时间段总价*房间数量
    },
    // 房间——选房间数量的列表
    roomNumber: {
      get() {
        let arr = [];
        for (let i = 1; i <= this.peoList.length; i++) {
          const element = this.peoList[i];
          arr.push({ text: i + "间", value: i });
        }
        return arr.slice(0, 5);
      },
      set(val) {
        this.roomNumber = val;
      },
    },
    // 房间——每间房住谁
    roomPeo: {
      get() {
        for (let i = 1; i <= this.checkRoomNumber; i++) {
          this.temp = this.temp || [];
        }
        return this.temp;
      },
      set(val) {
        this.temp = val;
      },
    },
    // 行旅剔除超标人员
    newPersonList: function () {
      if (this.hotelticket.corp == "行旅") {
        let result = [];
        let price = parseInt(this.roomticket.ratePlans[0].averageRate);
        this.personList.forEach((e) => {
          if (price <= parseInt(e.hotelStand)) result.push(e);
        });
        return result;
      } else return this.personList;
    },
  },
  activated() {
    this.detailShow = false;
    this.wrapper = false;
    window.setTitle("入住信息");
    this.recommend();
    if (!(0 in this.newPersonList)) {
      this.$toast.fail("暂无合规人员，请选择其他房型。");
    }
  },
  data() {
    return {
      detailShow: false, // 明细开关
      // 超标
      showPicker1: false,
      columns1: REASONS,
      compliant: false, // 超标填表开关
      explanation: REASONS[0], // 超标类型
      explanation1: "", // 超标备注
      explanationText: [], // 超标说明
      peoList: [],
      wrapper: false, // 遮罩层
      wrapper1: false, // 遮罩层菊花款
      checkRoomNumber: 1, // 房间数
      result: [], // 当前已选人员
      temp: {}, // roomPeo的辅助变量
      arrivetime: "20:00", // 到店时间
      showPickerTime: false, // 时间选择器
      columns: [
        {
          values: TimePickList,
          defaultIndex: 13,
        },
      ],
    };
  },
  methods: {
    // 选超标理由
    onConfirm1(value) {
      this.explanation = value;
      this.showPicker1 = false;
    },
    // 房间——选择人员同步修改人员列表的roomNo，当person的roomNo为对应房号。0或不存在该key表示未分配房间
    change(data, index) {
      this.peoList.forEach((element) => {
        let hasId = data.includes(element.idCard);
        if (hasId) element.roomNo = index;
        if (
          element.hasOwnProperty("roomNo") &&
          element.roomNo == index &&
          !hasId
        )
          element.roomNo = 0;
      });
    },
    // 房间——自动选人
    recommend() {
      let result = Recommend(this.roomticket.capacity, this.newPersonList);
      this.checkRoomNumber = result.total;
      this.peoList = result.personList;
      this.peoList.forEach((element) => {
        delete element.isViolation;
        delete element.violationContent;
        delete element.violationReasonChinese;
        delete element.violationReasonRemark;
      });
      this.result = result.newPersonList;
      this.roomPeo = result.roomPeo;
    },
    // 房间——切换房间数量
    roomNumChange(data) {
      for (++data; data < 6; data++) {
        this.change(this.roomPeo[data], 0);
        this.$set(this.roomPeo, data, []);
      }
    },
    // 选择到店时间
    onConfirmTime(time) {
      this.arrivetime = time[0];
      this.showPickerTime = false;
    },
    // 提交申请单，通过的话调用抢座
    subimtCompliance(action, done) {
      if (action === "confirm") {
        this.$refs.editCompliant
          .validate()
          .then(() => {
            this.peoList.forEach((element) => {
              if (
                parseInt(element.hotelStand) <
                this.roomticket.ratePlans[0].averageRate
              )
                element.isViolation = true;
              element.violationContent = this.explanation;
              element.violationReasonChinese = this.explanation;
              element.violationReasonRemark = this.explanation1;
            });
            this.onSubmit();
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    // 提交订单
    async onSubmit(needCheck = true) {
      if (needCheck) {
        // 如果用户允许删除下单房间就不判断了
        let nullRoomNumber = [];
        let peoList = Object.values(this.roomPeo);
        for (let i = 0; i < this.checkRoomNumber; i++) {
          const element = peoList[i];
          if (element.length == 0) nullRoomNumber.push(i + 1);
        }
        if (0 in nullRoomNumber) {
          return this.$dialog
            .confirm({
              title: "提示",
              message: `房间${nullRoomNumber.join(
                "、"
              )}没有选择入住人，继续提交订单将不会预订空房间。`,
            })
            .then(() => {
              onSubmit(false);
            })
            .catch(() => {
              return;
            });
        }
      }
      let errarr = [];
      let ids = Object.values(this.roomPeo).flat(); // 对象转数组并合并二维数组
      let peos = this.peoList.filter((e) => {
        // 已选人员列表
        return ids.includes(e.idCard);
      });
      // 检查已选人员是否合规
      let maxPrice = 0; // 最高房屋价格
      this.roomticket.ratePlans[0].nightlyRates.forEach((e) => {
        maxPrice = Math.max(maxPrice, e.price);
      });
      peos.forEach((element) => {
        if (parseInt(element.hotelStand) < maxPrice)
          if (!element.isViolation) errarr.push(element);
          else {
          }
      });
      // 如果有不合规
      if (errarr.length > 0) {
        this.explanationText = errarr;
        this.compliant = true; // 打开超标弹窗
        return;
      }
      // 行程单余额控制
      this.wrapper1 = true;
      let checkPrice = await this.$store.dispatch(
        "project/updateAppPrice",
        this.totalPrice
      );
      this.wrapper1 = false;
      if (this.travelInfo.isControl == "yes" && !checkPrice) {
        return this.$toast.fail("行程限额不足");
      }
      let time = this.arrivetime;
      this.wrapper = true;
      createHotelOrder({
        ratePlan: this.roomticket.ratePlans[0],
        roomCount: this.checkRoomNumber,
        comeDate: this.hotelOrderInfo.checkInDate,
        leaveDate: this.hotelOrderInfo.departDate,
        // totalAmount: this.totalPrice,
        latestCheckTime: time.includes("次日")
          ? "0" + time.fliterNumber() + ":00"
          : time,
        customers: peos,
        contactPerson: this.travelInfo.loginUser,
        hotelInfo: this.hotelticket,
        applyNo: this.travelInfo.appId,
      })
        .then(async (response) => {
          this.$store.dispatch("history/setOrderItem", response.data);
          this.$router.push({ name: "plan" });
        })
        .catch((err) => (this.wrapper = false));
    },
  },
};
</script>

<style lang="less" src="@/styles/orderCommon.less" scoped></style>
<style lang="less" scoped>
.null-peo {
  position: absolute;
  right: 0;
}
</style>
<style lang="less">
.ticket .auto-cell-reverse .van-cell__value > span {
  color: #969799;
}
// 修改element的select
.select {
  border: 0;
  border-bottom: 1px solid #f5f6f7;
  padding: 2px 0 1px;
  width: 100%;
  width: calc(100% - 16px);
  float: right;
  .el-input__prefix {
    left: 0;
    .select-title {
      font-weight: 400;
      padding: 7px 16px 7px 0;
    }
  }
  .el-select__tags {
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
    padding: 0 8px 0 70px;
  }
  input {
    border: none;
    text-align: right;
  }
  .el-input__suffix {
    right: 12px;
  }
  .el-input__inner {
    padding-left: 80px;
  }
}
.peo-pop {
  min-width: auto !important;
  right: 2rem;
  width: 8rem;
  text-align: center;
  left: auto !important;
}

.checkIn {
  .van-dropdown-menu__bar {
    height: 24px;
    background-color: inherit;
  }
  .van-dropdown-menu__title {
    font-size: 14px;
    padding-left: 0;
  }
  .van-dropdown-menu__item {
    justify-content: flex-end;
  }
}
</style>
