<template>
  <!-- 吹蜡烛的加载动画 -->
  <div class="body">
    <div class="wrapper">
      <img src="/loading.gif" alt="" width="100%">
    </div>
    <p class="bottomp">{{text}}，请稍后...</p>
  </div>
</template>

<script>
export default {
  name: "LoadingAnimate",
  props: {
    text: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bottomp {
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  margin: 0 0 calc(50% - 100px);
}
</style>